import React, {useContext, useState, useEffect} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import jwtDecode from "jwt-decode";

import AuthAPI from "../../services/AuthAPI";
import AuthContext from "../../contexts/AuthContext"

import Logo from '../../images/Logo Espelia.svg';

import { CssBaseline,
         Toolbar,
         AppBar,
         Typography,
         Tabs,
         Tab,
         Link,
         Button,
         Grid,
         IconButton,
         Tooltip 
        } from '@material-ui/core';

import { RiLogoutBoxRLine } from "react-icons/ri";

import Swal from 'sweetalert2';
//styling
import * as styling from '../../services/styling';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100px'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: styling.navbarBackgroundColor
    },
    title: {
        flexGrow: 1,
    },
    containerLogo: {
        width: 150,
        marginTop: 10,
    },
    demo2: {
        backgroundColor: styling.toolbarBackgroundColor,
    },
    logo:{
      width: '100%',
      height: '100%',
      margin: 0,
      padding: 0,      
    },
}))

const StyledTabs = withStyles({
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > span': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: 'white',
      },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);
  
const StyledTab = withStyles((theme) => ({
    root: {
      textTransform: 'none',
      color: '#FFF',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(15),
      marginRight: theme.spacing(1),
      '&:focus': {
        opacity: 1,
      },
    },
}))((props) => <Tab disableRipple {...props} />);


const Navbar = ({history}) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [token, setToken] = useState();
    const [roleSuperAdmin, setRoleSuperAdmin] = useState(false);

    const {isAuthenticated, setIsAuthenticated} = useContext(AuthContext);

    const handleLogout = () => {
      AuthAPI.logout();
      setIsAuthenticated(false);
      history.replace('/connexion');
    }
  

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const getDecodedToken = () => {
      const tokenBrut = window.localStorage.getItem("authToken");
      if(tokenBrut){
        const decodedToken = jwtDecode(tokenBrut);
        setToken(decodedToken);
        
      }
    }

    useEffect(() => {
      if(token){
          token.roles[0] === "SUPER_ADMIN" || token.roles[0] === "ADMIN" ? setRoleSuperAdmin(true) : setRoleSuperAdmin(false); 
      } 
  }, [token]);

  
    useEffect(() => {
      getDecodedToken();
    }, []);

    const logOutExpiredToken = () => {
      if(AuthAPI.isAuthenticated() === false){
        Swal.fire({
          icon: 'error',
          title: 'La session a expiré.',
          text: 'Veuillez-vous reconnecter.',
      });
        handleLogout();
      }
    }
    useEffect(() => {
      logOutExpiredToken();
    });

    return (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <div className={classes.containerLogo}>
                <a href="/#/accueil"><img className={classes.logo} src={Logo} alt="logo" /></a>
              </div>
              {!isAuthenticated ? 
                <>
                  <Link to="/connexion" >
                    <Button color="white">Login</Button>
                  </Link>
                </> 
              : 
                <div className={classes.login}>
                  <Tooltip title="Déconnexion">
                    <IconButton  className={classes.login} onClick={handleLogout} color="inherit" aria-label="déconnexion">
                      <RiLogoutBoxRLine />
                    </IconButton >
                  </Tooltip>
                </div>
              }              
            </Grid>
            </Toolbar>
            <div className={classes.demo2}>
                <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example">
                    <StyledTab label="Accueil" href="/#/accueil" />
                    {roleSuperAdmin === true ?
                          <StyledTab label="Administration" href="/#/administration" />
                    : 
                      null
                    }
                </StyledTabs>
                <Typography className={classes.padding} />
            </div>
          </AppBar>
        </div>
    )
}

export default Navbar;