import React, {useState, useEffect} from 'react';
import {HashRouter, Route, Switch, withRouter, Redirect} from 'react-router-dom';

import './App.css';

import Accueil from './templates/accueil';
import Administration from './templates/administration';
import Connexion from './templates/connexion';
import OublieMdp from './templates/oublieMdp';
import PrivateRoute from '../src/components/principal/privateRoute';
import AuthAPI from './services/AuthAPI';
import AuthContext from './contexts/AuthContext'

import Navbar from './components/principal/navbar';
//to Fix scroll top
import ScrollIntoView from "./components/principal/ScrollIntoView";

import { createMuiTheme,MuiThemeProvider  } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

AuthAPI.setup();

function App() {
  const theme = createMuiTheme({
    palette: {
      primary: {
        light: '#757ce8',
        main: '#3f50b5',
        dark: '#002884',
        contrastText: '#fff',
      },
    },

  });
  const NavbarWithRouter = withRouter(Navbar);
  const [isAuthenticated, setIsAuthenticated] = useState(AuthAPI.isAuthenticated());
  const [access, setAccess] = useState();

  const contextValue = {isAuthenticated, setIsAuthenticated};

  useEffect(() => {
    setAccess(AuthAPI.getDecodedToken());
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider value={contextValue}>
      <HashRouter>
        {isAuthenticated ? <NavbarWithRouter /> : null}
        <Box>
        <MuiThemeProvider theme={theme}>
            <ScrollIntoView>
              <Switch>
                <Route exact path="/connexion" component={Connexion} />
                <Route exact path="/motdepasseoublie" component={OublieMdp} />
                <PrivateRoute exact path="/accueil" component={Accueil} />
                {access ? 
                    <PrivateRoute exact path="/administration" component={Administration} />
                  :
                    <Redirect to="/accueil" />
                }
                <Redirect to="/accueil" />
              </Switch>
            </ScrollIntoView>
          </MuiThemeProvider>
        </Box>
      </HashRouter>
    </AuthContext.Provider>
  );
}

export default App;
