import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import axios from "axios";
import { CSV_API } from "../config.js";
import { Button, TextField, Grid, Box, Typography, Input } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';



const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundColor: 'grey',

    },
    header: {
        paddingTop: 50,
        textAlign: 'center',
        color: 'white', 
    },
    link: {
        "&:hover": {
            opacity: 0.5
        }
    },
    headerSub: {
        "& a": {
            textDecoration: 'none',
            color: 'white',
        },
    },
    boutonAdd: {
        color:'#69aec4',
    },
    boutonDelete: {
        color: 'grey',
    },
    button: {
        backgroundColor: "#69aec4",
        color: 'white',
        "&:hover": {
            backgroundColor: "white",
            color: "#69aec4",
        },
        borderRadius: 15,
    },
    subtitle: {
        height: 55,
        width: 250,
        backgroundColor: "#69aec4",
        borderRadius: 20,
        textAlign: 'center',
        marginBottom: 50,
        [theme.breakpoints.up('sm')]: {
            margin : '0 auto',
            width : '50vw',            
        },
    },
    parametrage: {
        "& h3": {
            color: 'white',
            paddingTop: 15,
        },
        backgroundColor: 'white',
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
        paddingBottom: 20
    },
    formContainer:  {          
        [theme.breakpoints.up('sm')]: {
            padding : 10,
            margin : '30px auto',
            width : '50vw',           
        },              
    },
    form: {
        paddingTop: 50,
        textAlign: 'center',
        width: 250,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
        maxWidth: 500,
    },

    inputLabel: {
        minWidth: 250
    },
    showValue:{        
        color : 'black', 
        textAlign: 'justify',
        minHeight : 80,
        paddingLeft: 20,
        marginBottom : 10,
        fontSize : '1.1rem',
    },
    animDown : {
        [theme.breakpoints.up('sm')]: {
            padding : 10,
            margin : '30px auto',
            width : '50vw',  
            paddingLeft: '20%' ,        
            paddingRight: '15%' ,        
        }, 
    }

}));

function getStyles(name, sirenName, theme) {
    return {
        fontWeight:
            sirenName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

const yearsList = ['2014', '2015', '2016', '2017', '2018', '2019'];

const CircularProgressWithLabel = (props) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography  component="div" color="inherit" >{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const Accueil = () => {
    const classes = useStyles();
    const theme = useTheme();

    const [sirenName, setSirenName] = useState('');
    const [sirenTab, setSirenTab] = useState([]);

    const handleChangeSiren = (event) => {   
        const regExp = /[a-zA-Z]/g;
        if (!(regExp.test(event.target.value))){
            setSirenTab(event.target.value.replace(/\s+/g, '').split(',').filter(e =>  e));
            setSirenName(event.target.value);
        }else{
            return
        }

    };
    const [yearName, setYearName] = useState([]);
    const handlechangeYear = (event) => {
        setYearName(event.target.value);

    }

    const [error, setError] = useState({
        year: false,
        siren: false
    }
    );

    const [downloadFiles, setDownloadFiles] = useState(false);
    const [progressDown, setProgressDown] = useState(0);

    const handleclick = async () => {

        if (sirenTab.length === 0) {
            setError(prevState => {
                return { ...prevState, siren: true }
            });
        }
        if (yearName.length === 0) {
            setError(prevState => {
                return { ...prevState, year: true }
            });
        }
        if ((sirenTab.length === 0) || (yearName.length === 0)) {
            return
        } else {
            setError({
                year: false,
                siren: false
            });
        }
        try {
            setDownloadFiles(true);

            const param = {
                sirens: sirenTab,
                years: yearName
            };
            axios({
                url: CSV_API,
                method: 'POST',
                data: param,
                responseType: 'blob', 
                onDownloadProgress: progressEvent => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setProgressDown(percentCompleted)
                }
            }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const contentDispositionHeader = response.headers['content-disposition'];
            const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
            const fileName = result.replace(/"/g, '');
            link.href = url;
            link.setAttribute('download', fileName); 
            document.body.appendChild(link);
            link.click();
            setDownloadFiles(false);
            setProgressDown(0);
            })

        } catch (error) {
            setDownloadFiles(false);
            setProgressDown(0)
            console.log(error)
        }
    }



    return (
        <div className={classes.root}>
            <header className={classes.header}>
                <h1>Extraction des balances comptables</h1>
                <h2>
                    Bienvenue sur l'utilitaire Espelia d'extraction de balances comptables - sauf M49 
                </h2>
                <p className={classes.headerSub}>
                    Les données des balances comptables que vous allez télécharger sont extraites du site <a className={classes.link} href="//data.gouv.fr" target="_blank" rel="noopener noreferrer"><strong>data.gouv.fr</strong></a> (License ouverte v2.0 - Etalab)
                </p>
            </header>
            <section id='parametrage' className={classes.parametrage}>
                <div className={classes.subtitle}>
                    <h3>Paramétrage de l'extraction</h3>
                </div>
                <Grid className={classes.formContainer}
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                
                >
                        <Grid item xs={12} sm={12} md={6}>                            
                                <TextField
                                    id="siren-input"
                                    label="SIREN concernés"
                                    variant="outlined"
                                    onChange={handleChangeSiren}
                                    multiline
                                    helperText="Veuillez saisir le(s) numéro(s) séparé(s) par une une virgule"
                                    value={sirenName ? sirenName : ''}  
                                    error={error.siren}
                                />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            {(sirenTab.length > 0) ?                                
                                <div className={classes.showValue}>
                                   <span style={{color : 'grey', fontSize : '1rem'}}>Saisie :<br/></span>
                                   <p> 
                                        {sirenTab.map((siren, index) => (
                                            '\u00A0'+ siren + ",  "  
                                        ))}
                                    </p>
                                </div>
                            : null}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.formControl}>
                                <InputLabel className={classes.inputLabel} id="mutiple-name-label">Année(s) d'extraction</InputLabel>
                                <Select
                                    error={error.year}
                                    labelId="mutiple-year-label"
                                    id="mutiple-year"
                                    multiple                                    
                                    value={yearName}
                                    onChange={handlechangeYear}
                                    input={<Input style={{ minWidth: 300 }} />}
                                    MenuProps={MenuProps}
                                    renderValue={(yearsList) => {
                                        let nameTab = [];
                                        let nameString;
                                        yearsList.forEach(element => {
                                            nameTab.push(element)
                                        });
                                        nameString = nameTab.join(', ');
                                        return <TextField id="standard-multiline-flexible- 
                                                years"
                                            multiline
                                            value={nameString}
                                            variant="outlined"
                                            style={{ width: 400 }}
                                        >
                                        </TextField>
                                    }}
                                >
                                    {yearsList.map((year) => (
                                        <MenuItem key={year} value={year} style={getStyles(year, yearName, theme)}>
                                            {year}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>                            
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.form}>
                                <Button title="soumettre le formulaire" className={classes.button} onClick={() => handleclick()}>Valider</Button>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                        {downloadFiles ?
                            <div  style={{ backgroundColor: '#69aec4',borderRadius : 15, color:"white",textAlign: 'center'}} className={classes.animDown}>
                                <h3>Téléchargement en cours</h3>
                                <div >
                                    <CircularProgressWithLabel value={progressDown} color='secondary' size='6em' />
                                </div>
                            </div>
                        : null}
                        </Grid>
                    </Grid>

            </section>

        </div>
    )
}

export default Accueil;